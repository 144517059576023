const $toggles = document.querySelectorAll('.act-share__toggle');

if ($toggles.length) {

    $toggles.forEach((el) => {
        el.addEventListener('click',(e)=>{
            actShareStickyToggle()
            e.preventDefault();
        })
        el.addEventListener('keydown',(e)=>{
            if(e.keyCode == 32 || e.keyCode == 13){
                actShareStickyToggle()
                e.preventDefault();
            }
        })
    })
    
    let defaultState = 'open';
    
    //console.log(document.body.clientWidth);
    if(localStorage.getItem('act-share-open')){
        defaultState = localStorage.getItem('act-share-open');
    }else if(document.body.clientWidth <= 980){
        defaultState = 'closed';
    }
    if(defaultState == 'open'){
        document.querySelectorAll('.act-share').forEach((el)=>{
            el.classList.add('act-share--is-open');
        })
    }
    
    function actShareStickyToggle(){
        document.querySelectorAll('.act-share').forEach((el)=>{
            el.classList.toggle('act-share--is-open');
            if(el.classList.contains('act-share--is-open')){
                localStorage.setItem('act-share-open','open');
            }else{
                localStorage.setItem('act-share-open','closed');
            }
        })
    }
}